$myriad-regular: './fonts/MyriadPro-Regular.otf'
$myriad-semibold: './fonts/Myriad Pro Bold SemiExtended.ttf'
$myriad-bold: './fonts/MyriadPro-Bold.otf'

@font-face
    font-family: 'Myriad Pro'
    src: url($myriad-bold)
    font-weight: bold

@font-face
    font-family: 'Myriad Pro'
    src: url($myriad-semibold)
    font-weight: bolder

@font-face
    font-family: 'Myriad Pro'
    src: url($myriad-regular)

*
    font-family: 'Myriad Pro'

.bg-gradient-banner
    @media screen and ( min-width: 1500px )
        background: linear-gradient(90deg, white 0%, white 50%, transparent 60%)

.ourServices
    &::-webkit-scrollbar
        display: none
.scrollbar-styles
    &::-webkit-scrollbar
        width: 0.5rem
    &::-webkit-scrollbar-thumb
        background-color: #E5E5E5
        border-radius: 0.5rem
