@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .btn-primary {
    @apply flex justify-center items-center bg-primary text-sm text-white w-7rem rounded-md h-2.5rem 800px:w-24 hover:bg-secondary transition-all duration-300 ease-in-out scale-105;
  }
  .btn-secondary {
    @apply text-xl px-7 w-11rem pt-1;
    width: 10rem;
  }

  .button-questions {
    @apply w-14 h-14 ml-6 1024px:px-0 bg-primary flex justify-center items-center rounded-full mt-5 cursor-pointer transition-all duration-700 ease-in-out scale-105;
  }

  .button-questions > .text-container {
    @apply overflow-hidden w-0 transition-all duration-700 ease-in-out;
  }

  .button-questions:hover {
    @apply 800px:w-64;
  }

  .button-questions:hover .text-container {
    @apply w-48;
  }
  .floating-button {
    @apply hidden 800px:flex 800px:w-20 800px:rounded-tl-full 800px:rounded-bl-full 800px:h-14 800px:bg-floating-button 800px:cursor-pointer 800px:fixed 800px:bottom-12 800px:right-0 800px:opacity-100 800px:px-3 800px:py-2 800px:z-50 800px:mb-12;
  }
  .floating-button > .text-container {
    @apply overflow-hidden w-0 transition-all duration-300 ease-in-out;
  }

  .floating-button:hover {
    @apply 800px:w-40;
  }

  .scroll-transparent::-webkit-scrollbar {
    background: transparent;
  }

  .scroll-transparent::-webkit-scrollbar-track {
    background: transparent;
  }

  .scroll-transparent::-webkit-scrollbar-thumb {
    background: transparent;
  }

  .scroll-transparent::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

}
