/* ReactQuillComponent.module.css */

.truncate {
  width: 100;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  resize: vertical;
}

.markdown h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
}

.markdown h2 {
  font-size: 1.75em;
  margin-bottom: 0.5em;
}

.markdown h3 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.markdown ol,
.markdown ul {
  margin-left: 1.5em;
}

.markdown li {
  margin-bottom: 0.5em;
}

.markdown ul li {
  list-style-type: disc;
}

.markdown ol li {
  list-style-type: decimal;
}
